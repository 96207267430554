@media only screen and (min-width: 320px) and (max-width: 969px) {  
    body { 
      min-width: 100px;
    }
    .absoluteWhite {
      width: auto;
    }
    .add_partside {
        float: none;
        width: 100%;
    }
    .tickets i {
        width: 70px;
        height: 70px;
    }
    .tickets {
        float: none;
        width: 250px;
        height: 70px;
        padding: 0;
    }
    .tickets span {
        bottom: 20px;
        text-align: left;
        left: 80px;
    }
}
  