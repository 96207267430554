.absoluteWhite {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--white);
}
.pagecontent {
    background-color: var(--white);
}

.css-1n7v3ny-option {
    background-color: var(--graylight) !important;
}
.bgcolor-white {
    background-color: var(--white);
}
.gray-border {
    border: 1px solid var(--grayoutline);
    padding: 0 5px;
}
.dashboardTickets .smallData {
    font-size: 16px;
    color: var(--secondarydark);
}
.__td_values_new_css {
    border: 1px solid var(--grayoutline);
    padding: 5px;
}

.passwordEye {
    position: absolute;
    top: 15px;
    right: 10px;
}

.waiting-operation {
    margin: 10px 0;
    padding: 8px 4px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}

.supportTicket .Dropdown__root___3ALmx {
    display: none;
}

.display-border-in-bom {
    border: 1px solid var(--grayoutline);
    min-height: 30px;
    padding: 2px 7px;
    background-color: #fff;
}

.createdText.disabled {
    background-color: #f9f9f9;
}

.danger-input-border input {
    border: 1px solid red !important;
    background-color: pink !important;
}

.generatePriceBtn {
    padding: 5px 15px;
    margin: 0 0 4px 0;
    border-radius: 2px;
    font-size: 15px;
    width: 150px;
    text-align: center;
    cursor: pointer;
    background-color: var(--secondary);
    border: 1px solid  var(--secondary);
    color: var(--white)
}
.generatePriceBtn:hover {
    border: 1px solid var(--primary);
    background-color: var(--primary);
}

.generatePriceBtn.disabled {
    background-color: var(--gray);
    border: 1px solid var(--gray);
}

.addPartyIcon {
    font-size: 18px;
}

.__offline_message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20001;
    background-color: rgba(0,0,0,0.8);
    text-align: center;
    padding: 10px 0;
    color: var(--white);
    transition: display 2s;
}

.select-search__select {
    position: absolute;
    z-index: 10;
    background-color: var(--white);
    width: 100%;
    padding: 5px;
    border: 1px solid var(--graylight);
    border-top: none;
}

.select-search__options {
    padding: 0;
    margin: 0;
}
.select-search__row {
    text-decoration: none;
    list-style: none;
}

.select-search__option {
    border: none;
    background-color: var(--white);
    padding: 6px 0px;
    width: 100%;
    text-align: left;
}


.ticketResponses {
    float: right;
    width: 85%;
    margin: 20px 25px;
}

.ticketMessageTitle {
    font-weight: bold;
    font-size: 18px;
}

.ticket-list-ul {
    padding: 0;
    margin: 0;
}

.ticket-list-ul .ticket-list-description {
    color: grey;
}

.ticketResponses li {
    font-size: 15px;
    margin: 0;
    padding: 20px 10px 20px 65px;
    list-style: none;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 40px;
    border-bottom: 1px solid #d9d9d9;
}

.ticketResponses li:nth-child(odd) {
    background-color: var(--graylight);
}
/* .ticketResponses li:nth-child(even) {
    background-color: var(--graydark);
} */

.ticketResponses li.user-icon{
	background-image: url('./theme/assets/Images/user.png');
}

.ticketResponses li.oemup-icon{
	background-image: url('./theme/assets/Images/applogo.png');
}

.select-search__option.is-selected {
    background-color: var(--graylight);
}

.select-search__input {
    text-transform: capitalize;
}

span.clickable__source {
    text-decoration: underline;
    color: var(--primary);
    cursor: pointer;
}
.pageLoading {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: progress;
    background-color: rgba(255,255,255,0.5);
    z-index: 2001;
}

.color__code_for_group {
    width: 40px;
    height: 40px;
    background-color: gray;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--graylight);
    cursor: pointer;
}

.color__code_for_group.selected {
    border: 2px solid var(--black);
}

.color-red {
    color: var(--danger) !important;
}

.__quotation_pdf_companyName {
    font-family: 'denmarkregular';
}
.__pdf_error_message {
    padding: 100px 0;
    text-align: center;
    font-size: 15px;
}


.DraftEditor-editorContainer {
    font-family: Arial,Helvetica,sans-serif;
}

.disableFieldCover {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgb(255 255 255 / 50%);
    width: 100%;
    height: 100%;
}


.headerArea {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.heading {
    font-weight: bolder;
    font-size: 26px;
    float: left;
    width: 30%;
    padding-left: 0;
}

.fullWidthHeading {
    width: 100% !important;
}

.editPageTitle {
    width: 100%;
}

.start_stop_button___mpp {
    font-size: 22px;
}

.password-eye-user-setting {
    position: absolute;
    top: 3px;
    right: 4px;
    font-size: 17px;
    color: var(--primarylight);
}



.doc_table_title {
    float: left;
    width: 13%;
    text-align: right;
    padding: 2px 5px;
}
.doc_table_value {
    float: left;
    width: 87%;
}

/* PAGE NEW TITLES CSS */

.page-header {
    margin: 20px 0;
}

.page-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary);
    margin: 15px 0;
}
.sub-page-title {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 10px;
    clear: both;
}

.page-small-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary);
    margin: 15px 0;
}

.head-select-box select {
    height: 40px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 18px;
    margin: 15px 0;
    padding: 0 8px;
    -webkit-appearance: none;
    appearance: none;
    width: 180px;
    background-color: transparent;    
    background-image: url('./theme/assets/Images/downarrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 32px auto;
}

.head-select-box input {
    height: 38px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 18px;
    margin-top: 15px;
    padding: 0 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 140px;
    background-color: transparent;
    margin-left: 10px;
}

.head-import-buttons {
    margin: 12px 0;
}
.head-import-buttons .button {
    margin: 2px;
    text-align: center;
    font-size: 17px;
    padding: 10px 15px;
    box-shadow: inset 0 0 0 1px var(--primarylight);
    border-radius: 5px;
    float: left;
    cursor: pointer;
}

select.print-pdf-options {
    height: 45px;
    min-width: 114px;
    margin-top: 2px;
    border-radius: 6px;
    float: left;
    font-size: 17px;
    padding: 10px 8px;
    margin-right: 5px;
}

.head-import-buttons .button.disabled {
    box-shadow: inset 0 0 0 1px var(--gray);
    color: var(--gray)
}
.head-import-buttons .button.disabled svg {
    color: var(--gray)
}

.head-import-buttons .button.co__footer__button {
    box-shadow: inset 0 0 0 1px var(--secondary);
    font-size: 15px;
    padding: 8px 40px;
}

.button.co__footer__button.disabled {
    box-shadow: inset 0 0 0 1px var(--gray);
    color: var(--gray)
}

.view__customer__message {
    padding: 5px;
    margin-top: 1px;
}

.head-import-buttons .button svg {
    margin-bottom: 2px;
    margin-left: 2px;
    color: var(--primarylight);
}

.head-import-buttons a.button {
    color: var(--black);
}

.input-file {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
    width: 100% !important;
    height: 38px !important;
}

.head-import-buttons .button:hover, 
.head-import-buttons .button:hover svg,
.head-import-buttons a.button:hover{
    color: var(--white);
    background-color: var(--primarylight);
}

.head-import-buttons .button.disabled:hover svg {
    background-color: transparent;
    color: var(--gray)
}

.doc_file_width_updated {
    width: 195px !important;
}
.doc-saved-file {
    display: inline-block;
    margin-left: 5px;
    color: var(--secondary);
    cursor: pointer;
    z-index: 1001;
    position: relative;
}

.pump-register-doc-saved-file {
    display: inline-block;
    margin-left: 5px;
    color: var(--secondary);
    cursor: pointer;
    position: relative;
}


.pump-register-buttons .add_btnBack{
    background-color: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
}

.dashboard-date-filter {
    float: left;
    margin: 5px;
}

.dashboard-date-filter input {
    border: 1px solid var(--secondary);
    height: 28px;
}

.dashboard-date-filter-title {
    font-size: 12px;
    color: var(--primarylight);
    margin-bottom: 4px;
    font-weight: bold;
}

.user-rights-select .select2-results__options {
    text-transform: lowercase !important;
}

.head-import-buttons .button.disabled:hover{
    color: var(--gray);
    background-color: transparent;
}


.head-import-buttons .joined-buttons {
    float: left;
    margin: 2px;
}

.head-import-buttons .joined-buttons .btn-left {
    background: var(--white);

    padding: 8px;
    font-size: 25px;
    padding-bottom: 2px;
    padding-top: 6px;

    color: var(--secondary);
    box-shadow: inset 0 0 0 1px var(--primarylight);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-left: 2px;
    float: left;
    cursor: pointer;
}

.head-import-buttons .joined-buttons.__my_production_plan .btn-rght {
    padding: 8px;
    font-size: 25px;
    padding-bottom: 2px;
    padding-top: 6px;
    
}



.head-import-buttons .joined-buttons .btn-left:hover,
.head-import-buttons .joined-buttons .btn-left:hover svg{
    color: var(--white);
    background-color: var(--primarylight);
}

.head-import-buttons .joined-buttons .btn-left.active,
.head-import-buttons .joined-buttons .btn-left.active svg,
.head-import-buttons .joined-buttons .btn-rght.active,
.head-import-buttons .joined-buttons .btn-rght.active svg{
    color: var(--white);
    background-color: var(--primarylight);
}



.head-import-buttons .joined-buttons .btn-rght:hover,
.head-import-buttons .joined-buttons .btn-rght:hover svg{
    color: var(--white);
    background-color: var(--primarylight);
}

.head-import-buttons .joined-buttons .btn-rght {
    padding: 13px 10px 8px 12px;
    background: var(--white);
    font-size: 16px;
    box-shadow: inset 0 0 0 1px var(--primarylight);
    color: var(--secondary);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    float: left;
    cursor: pointer;
    margin-right: 2px;
}

.head-import-buttons .joined-buttons svg {
    margin-bottom: 6px;
    margin-left: 2px;
    color: var(--primarylight);
}

.permission__div {
    width: 92%;
    margin: 0 auto;
    margin-top: 10px;
}
.permission_resources_txt {
    padding-right: 10px;
}

.addNewCustomerContactField input[type="text"]{
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
    border-radius: 0;
}

/* BOOKING ITEM SCREENS */

.__co__booking__main_content {
    padding: 10px 0;
}
.__co__booking_item_list {
    margin-top: 20px
}
.__co__booking_item_list thead th {
    font-weight: 100;
    color: var(--primary);
}
.__co__booking_btn {
    background-color: var(--primary);
    color: var(--white);
    padding: 7px 0;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}

.__co_cancel_booking_btn {
    background-color: var(--secondary);
    color: var(--white);
    padding: 7px 0;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}

.__co_booking_disabled_btn {
    background-color: var(--gray) !important;
}

.__co__booking_back_btn {
    background-color: var(--secondary);
    color: var(--white);
    padding: 7px 0;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}
.__co_booking_bottom_button {
    padding: 7px 35px;
    margin-right: 3px;
}
.__co_book_checkboxe {
    margin-bottom: 5px
}
.__co_book_checkboxe label {
    float: left;
    margin: 0;
    cursor: pointer;
}
.__co_book_checkboxe input {
    float: left;
    margin-right: 5px;
    margin-top: 3px;
    cursor: pointer;
}
.__add_booking_create_btn {
    padding: 7px 20px;
    border: 1px solid var(--secondary);
    border-radius: 3px;
    cursor: pointer;
    margin-left: 3px;
}
.__add_booking_create_btn:hover {
    background-color: var(--secondary);
    color: var(--white);
    transition: background-color 0.5s;
}
.stock__maintain__add_booking {
    margin: auto 23px;
}
.ml10per {
    margin-left: 10%;
}
/* PAGE NEW TITLES CSS */

.ml23 {
    margin-left: 23px !important;
}

.__formBtnPrimary {
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    cursor: pointer;
    border-radius: 3px;
}
.__formBtnSecondary {
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white);
    cursor: pointer;
    border-radius: 3px;
}

.doc_delete_button {
    text-align: right;
    font-size: 18px;
}

/* New Form CSS */

.__formBtnSecondary:hover{
    background-color: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    transition: background-color 0.5s;
}
.__formBtnPrimary:hover {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: background-color 0.5s;
}
.__formTextVal {
    line-height: 30px;
}
.__formSelectElem {
    border-radius: 0;
    border: 1px solid var(--grayoutline);
    background-color: var(--white);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    width: 100%;
    padding: 5px;
    height: 30px;
}
.__formInputElem {
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
    width: 100%;
}

.confirm-box {
    left: 35% !important;
}
.confirm-dialog-box {
    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 4px 5px 9px 0px #c1c1c1;
}

.confirm-dialog-box .dialog-msg {
    font-size: 18px;
    margin-bottom: 10px;
}

.change-button {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 999;
}
.add_btnBack.change-button {
    font-size: 12px;
    right: 0;
}


.confirm-dialog-box .dialog-button {
    float: left;
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white);
    cursor: pointer;
    border-radius: 3px;
    margin-right: 10px;
}


.white-bg {
    background-color: var(--white) !important;
}

.headerArea .selectbox{
    width: 30%;
    float: left;
    text-align: center;
}

._Bbaspace3px {
    margin-right: 3px;
}

.capitalize {
    text-transform: capitalize;
}
.heading.cashflow {
    width: 25%;
}
.headerArea .selectbox.cashflow {
    width: 50%;
}

.headerArea .selectbox select {
    height: 40px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 18px;
    margin-top: 15px;
    padding: 0 8px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    width: 180px;
    background-color: transparent;
    background-image: url('./theme/assets/Images/downarrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 32px auto;
}

.cursor-pointer{
    cursor: pointer !important;
}

.tableElemContain .value {
    position: relative;
    width: 90%;
}
.tableElemContain span.dash{
    position: absolute;
    top: 6px;
    margin-left: 4px;
    font-size: 15px;
    color: gray;
}

.headerArea .selectbox input {
    height: 38px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 18px;
    margin-top: 15px;
    padding: 0 8px;
    -webkit-appearance: none;
    appearance: none;
    width: 140px;
    background-color: transparent;
    margin-left: 10px;
}

.tableAddButton, .tableAddButton a{
    font-size: 17px;
    color: var(--secondary);
    position: relative;
    vertical-align: middle;
}

.selectColumns {
    background-color: var(--white);
    z-index: 1001;
    border: 1px solid var(--gray);
}

.popup-content {
    z-index: 1001 !important;
}

.export-list-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 2px;
    text-align: center;
    padding: 11px 15px;
    float: left;
    cursor: pointer;
}

.export-list-button {
    margin: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
}


.selectColumns ul {
    text-decoration: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow: scroll;
}

.selectColumns ul li {
    text-decoration: none;
    margin: 20px 15px;
    list-style-type: none;
    font-size: 13px;
}

.selectColumns ul li span {
    display: inline-block;
    min-width: 110px;
}

a.addContactButton {
    top: -3px;
}
.tableHeader {
    font-size: 14px;
    font-weight: 100;
    color: var(--primary);
}

.createdText {
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--grayoutline);
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
}

.createdTextArea {
    border: 1px solid var(--grayoutline);
    padding: 0 5px;
    overflow: hidden;
    text-align: justify;
}

.createdText.text-capitalize.textarea-div {
    height: 64px;
    line-height: normal;
    padding-top: 7px;
    white-space: unset;
}

.createdTextTitle {
    height: 30px;
    line-height: 30px;
}

.tableHeadButton, .tableHeadButton a{
    color: var(--secondary);
    font-size: 17px;
}

.tableHeader span {
    text-decoration: underline;
}




table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    text-transform: capitalize;
    font-weight: normal;
  }

.order_product_group table th,
.order_product_group table td {
    padding: 2px !important;   
}

.order_product_group.view_customer_order_screen table th,
.order_product_group.view_customer_order_screen table td {
    padding: 10px 2px !important;
}
.order_product_group.view_customer_order_screen table,
.order_product_group.view_customer_order_screen table thead tr {
    border: 1px solid var(--gray)
}

.order_product_group.view_customer_order_screen table thead tr {
    background-color: var(--graylight) !important;
}


  .unitsofmeasure td {
      padding-left: 0;
  }

  thead th, thead td {
    padding: 2px 2px 2px 2px;
  }

.docPermission__class input {
    cursor: pointer;
}

.docPermission__class:hover {
    background-color: var(--graylight);
}

.tableElemContain input, 
.tableElemContain select {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
    width: 100% !important;
}

.inputbox, 
.selectbox {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
    width: 100% !important;
}

.tableElemContain input.width80 {
    width: 80% !important;
}

.tableElemContain input[type='checkbox'] {
    width: auto !important;
}

.selectFile__Popup__Item {
    background-color: var(--white);
    padding: 200px;
    border: 1px solid var(--gray);
}

.selectFile__Popup__Item input {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
    width: 100% !important;
}

.tableElemContain select {
    background-image: url('./theme/assets/Images/downarrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 25px auto;
}
.items.ElemContain,
th.tableElemContain {
    padding-top: 2px;
    padding-bottom: 2px;
}

.items.ElemContain input {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
}

.stocksetting table th.tableElemContain {
    padding-top: 3px;
    padding-bottom: 3px;
}




thead tr{background-color: var(--graylight)}
  
tr:nth-child(even){background-color: var(--graylight)}

.unitsofmeasure tr:nth-child(odd){background-color: var(--graylight)}
.unitsofmeasure tr:nth-child(even){background-color: transparent}

thead tr:first-child {
    background: none !important;
}
.__kDoEM__table tbody tr:hover {
    outline: 1px solid var(--secondary);
    outline-offset: -1px;
    background-color: var(--graydark) !important;
    cursor: pointer;
}

.tableContent tbody tr:hover {
    outline: 1px solid var(--secondary);
    outline-offset: -1px;
    background-color: var(--graydark) !important;
}
.tableContent tbody tr._order__danger__border:hover {
    background-color: pink !important;
}

.table_inside_table {
    background-color: var(--graylight) !important;
}

.table_inside_table tbody tr:hover {
    outline: none !important;
    outline-offset: -1px;
    background-color: var(--graylight) !important;
}

.table_inside_table thead tr:hover {
    outline: none !important;
    background-color: var(--graylight) !important;
}

.tableLoading {
    position: absolute;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 15px 0;
    font-size: 16px;
    background-color: rgba(255,255,255,0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    min-height: 100px;
}

.tdLoading {
    position: absolute;
    top: 49%;
    left: 42%;
}


.tableContent tbody tr.tableLoading:hover {
    outline: none;
    outline-offset: 0;
    background-color: rgba(255,255,255,0.6) !important;
}

.__add__po_free_text {
    height: 76px;
    border-radius: 0;
    border: 1px solid var(--grayoutline);
}


[role=tooltip].popup-content {
    width: auto !important;
    padding: 0 !important;
}

/* .tableHeader:hover {
    border-bottom: 1px solid var(--gray);
} */

.rbc-event-content {
    text-transform: capitalize;
    color: var(--white)
}



/* TASK MANAGER CSS START*/
.__task_manager_container {
    position: fixed;
    top: 0;
    right: 0;
    width: 330px;
    background-color: var(--white);
    z-index: 2001;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    height: 100%;
}

.__task_manager_container.open {
    transform: translateX(0);
}

.__task_menu_open {
    position: absolute;
    height: 50px;
    background-image: url(./theme/assets/Images/taskbg.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: -27px;
    top: 48px;
    cursor: pointer;
    padding: 10px 3px;
}
.__menu_open_icon {
    font-size: 22px;
    color: var(--white);
}
.__tasks_list_main_div{
    overflow: scroll;
}
.__task_manager_background_cover {
    position: fixed;
    background-color: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20002;
}
.__task_date_head {
    font-size: 15px;
    color: var(--secondary);
}
.__task_detail ul {
    margin: 0;
    padding: 0;
}
.__task_detail ul li {
    padding: 8px 0 8px 5px;
    font-size: 13px;
    cursor: pointer;
}
.__task_detail ul li:nth-child(odd){
    background-color: var(--graylight)
}
.__task {
    margin-bottom: 10px;
}
.__popup_screen_container {
    position: fixed;
    background-color: var(--white);
    z-index: 20002;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 40px;
    box-shadow: 1px 1px 8px 0px var(--gray);
    overflow: scroll;
    overflow-x: hidden;
}
li.__list_bold {
    font-weight: 600;
}
li.__list_done {
    text-decoration: line-through;
}

/* TASK MANAGER CSS START*/

.__quotation_pdf_container, 
.__pdf_footer_css div:first-child {
    width: 1024px !important;
    margin: 0 auto !important;
}

.__editCompanyDetailErrorMessage {
    margin: 10px;
    color: var(--danger);
    font-size: 15px;
}

._other_input_auto_witdh {
    width: auto !important;
}

.otherButtons {
    float: right;
    margin-top: 15px;
}
.firstbuttons {
    float: left;
    overflow: hidden;
}
.oButton {
    float: left;
    cursor: pointer;
}
.oButton svg {
    margin-top: -5px;
}

.oButton.one {
    padding: 8px;
    background: var(--white);
    font-size: 25px;
    padding-bottom: 2px;
    padding-top: 6px;
    color: var(--secondary);
    box-shadow: inset 0 0 0 1px var(--primarylight);

    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.oButton.one a{
    color: var(--secondary);
}


.oButton:hover a{
    color: var(--white);
    background-color: var(--primarylight);
}

.oButton.active {
    background: var(--secondary);
    color: var(--white);
}
.oButton.two {
    padding: 13px 10px 8px 12px;
    background: var(--white);
    font-size: 16px;
    box-shadow: inset 0 0 0 1px var(--primarylight);
    color: var(--secondary);

    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.oButton.thr {
    margin: 0 5px;
    text-align: center;
    font-size: 17px;
    padding: 10px 15px;
    box-shadow: inset 0 0 0 1px var(--primarylight);
    border-radius: 5px;
}

.oButton.fou {
    margin: 0 5px 0 0;
    text-align: center;
    font-size: 17px;
    padding: 10px 15px;
    box-shadow: inset 0 0 0 1px var(--primarylight);
    border-radius: 5px;
}
.downIcon {
    margin-bottom: -3px;
    margin-left: 4px;
    color: var(--primarylight);
}
.oButton:hover {
    color: var(--white);
    background-color: var(--primarylight);
}
.oButton:hover .downIcon{
    color: var(--white);
}

.tableContent.inner-page-table {
    padding: 25px;
}

.tableContent {
    /* padding: 25px; */
    background-color: var(--white);
}
.zIndex1001 {
    position: relative;
    z-index: 1001;
}
.tableContent tbody tr {
    cursor: pointer;
}
.mh400{
    min-height: 400px;
}
.full__page__height {
    height: 100vh
}
.tableContent .footerTitle {
    font-weight: bolder;
    font-size: 16px;
    color: var(--primary);
}
.datamenus {
    float: left;
    width: 20%;
    margin-right: 4%;
    border: 1px solid var(--gray);
}
.contentdata {
    margin-top: 10px;
}
.systemopts {
    padding: 6px 8px;
    border-bottom: 1px solid var(--gray);
    cursor: pointer;
    background-color: var(--graylight);
}
.systemopts:last-child {
    border: none;
}
.systemopts.active {
    background-color: var(--gray);
}
.systemopts:hover {
    background-color: var(--gray);
}


.paymentData {
    margin-top: 30px;
    padding: 10px 32px!important;
    display: inline;
    position: relative;
    z-index: 3;
    /* transition: color .25s ease-in-out; */
    text-align: center;
    font-size: 16px;
    color: var(--black);
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: inset 0 0 0 1px var(--secondarydark);
}

.paymentData:hover {
    background-image: none;
    text-decoration: none;
    color: var(--white);
    background-color: var(--primary); /* For browsers that do not support gradients */
    background-image: linear-gradient(254deg var(--secondarydark), var(--secondarylight));
}

.deletetext {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    color: var(--primary);
}

.addComponent .heading {
    float: left;
}

.addNewCustomer {
    float: right;
    width: 18px;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    color: var(--black);
}

.add_btnBack {
    float: left;
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white);
    cursor: pointer;
    border-radius: 3px;
}


.add_btnBack:hover{
    background-color: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    transition: background-color 0.5s;
}

.add_btnSave {
    float: left;
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
}

.add_btnSave:hover {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: background-color 0.5s;
}

.addSaveButton .disabled {
    float: left;
    width: 100px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    background-color: var(--graydark);
    border: 1px solid var(--graydark);
    color: var(--white);
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
}

.finish__button__mpp {
    width: auto;
    padding: 5px 30px;
}


.message-border {
    border: 1px solid #000;
    padding: 10px;
}

.text-transform-none {
    text-transform: none !important;
}




.finish__button__mpp.disabled {
    background-color: transparent;
    border: 1px solid var(--gray);
    color: var(--gray)
}
.finish__button__mpp.disabled:hover {
    background-color: transparent;
    border: 1px solid var(--gray);
    color: var(--gray)
}


.add_partside {
    float: left;
    width: 50%;
}

.add_inner {
    margin-bottom: 2px;
}

.mb2 {
    margin-bottom: 2px;
}

.add_inner.contactinfo {
    margin: 4px 0 12px 0;
}

.add_inner .title {
    float: left;
    width: 25%;
    padding: 5px 0;
    text-align: right;
    margin-right: 10px;
}

.add_inner .value {
    float: left;
    width: 70%;
    position: relative;
}
.add_inner .value.switch {
    margin: 5px 0;
}
.add_inner .value.switch .css-xjimrv-Switch {
    width: 26px !important;
    height: 16px;
}
.add_inner .value.switch .css-19a995p-Switch,
.add_inner .value.switch .css-gpmoc7-Switch {
    width: 12px;
}
.css-knn8xi-Switch,
.css-12hu0wa-Switch {
    border-radius: 8px !important;
}

.add_inner .value.companyname{
   padding: 5px 5px 5px 0px
}

.gstdetail_search_icon {
    position: absolute;
    top: -1px;
    right: 5px;
    font-size: 20px;
    color: var(--secondary);
}

.textarea-height-80 {
    height: 80px !important;
}

.add_inner .value select,
.add_inner .value input,
.add_inner .value textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
    border-radius: 0;
}



.add_inner .value textarea.expense_desc_textarea {
    height: 58px;
    white-space: pre-line;
}

.add_inner .value input[type='checkbox'],
.add_inner .value input[type='radio']{
    width: auto !important;
}

.shipment__booked_item__title {
    width: 12% !important;
    margin-right: 17px !important;
}

.__working_hours_check_wsg span {
    top: 0;
    display: block;
    position: absolute;
    left: 25px;
}

.term_radio_input {
    float: left;
    width: 30px;
    height: 30px;
    padding: 1px 0px;
    text-align: center;
}
.term_label {
    float: left;
    width: 160px;
    padding: 5px;
    height: 30px;
}
.term_radio_input input {
    width: auto !important;
}
.__imp_note {
    border: 2px solid var(--secondary);
}

.add_inner .value .__static_fixed_text {
    padding: 5px;
}

.add_inner .value select {
    border-radius: 0;
    border: 1px solid var(--grayoutline);
    background-color: var(--white);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding-right: 1.5emr;
}

.addNewCustomerContactTitle{
    float: left;
    width: 25%;
}

.__set_inline_content {
    display: inline-flex;
    align-items: center;
}

.add_outer_full_part .title {
    float: left;
    width: 13%;
    padding: 5px 0;
    text-align: right;
    margin-right: 3px;
    padding-right: 4px;
}

.add_outer_full_part .RichTextEditor__root___2QXK- {
    width: 85%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    float: left;
    border-radius: 0;
}

.select-box {
    border-radius: 0;
    border: 1px solid var(--grayoutline);
    background-color: var(--white);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding-right: 1.5emr;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
}

.textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    white-space: pre-line;
}
.textarea-height100 {
    height: 100px;
}

.input-box {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
}

.cursor-na {
    cursor: default !important;
}

.pg-tab-head {
    color: var(--primary);
    font-weight: 100;
}

.addNewCustomerContactTitle .title2 {
    padding: 5px 0;
    text-align: right;
}

.addNewCustomerContactField{
    float: left;
    width: 70%;
    margin-left: 10px;
    position: relative;
}
.add_inner .extrafieldforContact {
    padding: 2px 4px;
    position: relative;
}
.add_inner .addNewCustomerContactField select,
.add_inner .extrafieldforContact select {
    height: 30px;
    border-radius: 0;
    border: 1px solid var(--grayoutline);
    background-color: var(--white);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding-right: 1.5emr;
    width: 98%;
    padding: 5px;
}

.add_inner .extrafieldforContact input {
    width: 75%;
    margin-bottom: -1px;
    height: 30px;
    border: 1px solid var(--grayoutline);
    padding: 5px;
}

.add_inner .extrafieldforContact .address_fields .extraFieldCloseButton{
    position: absolute;
    top: 8px;
}

.add_inner .extrafieldforContact .checkboxInputs {
    width: 25%;
    float: left;
    text-align: center;
}

.add_inner .taxApplicableInput .checkboxInputs {
    width: 100%;
}
.add_inner .taxApplicableInput .checkboxInputs input {
    height: 25px;
}
.taxApplicableTitle {
    padding: 4px 0;
}

.datepickerIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: var(--secondary);
    font-size: 18px;
    z-index: 1;
}

.value .datepickerIcon{
    top: 7px;
}

svg.__date_picker_icon_for_working_hours {
    color: var(--secondary);
    position: absolute;
    font-size: 18px;
    top: 13px;
    right: 13px;
}

svg.__date_picker_icon_for_calendar_picker {
    color: var(--secondary);
    position: absolute;
    font-size: 18px;
    top: 8px;
    right: 5px;
}

svg.__date_picker_icon_for_company_working_hours {
    color: var(--secondary);
    position: absolute;
    font-size: 18px;
    top: 6px;
    right: 6px;
}

svg._date_picker_for_idle_time {
    color: var(--secondary);
    position: absolute;
    font-size: 18px;
    top: 6px;
    right: 6px;
}

.react-datepicker-popper[data-placement^="bottom"]{
    z-index: 1002;
}

.__pause_qty__detail_container {
    width: 232px;
    text-align: center;
    margin: 50px auto;
}

.operation__title {
    font-size: 16px;
    font-weight: bold;
}

.__pause_qty__title {
    font-size: 18px;
    white-space: nowrap;
    padding: 10px 0;
    text-align: left;
}

.draggable-class {
    cursor: all-scroll !important;
}

.fixedHeight30 {
    height: 30px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.userPermissionChecks {
    float: left;
    width: 25%;
    text-align: center;
    margin-top: 5px;
}


.accManagerTitle {
    margin-top: 5px;
}


.removeButton {
    margin-top: 5px;
}

.accountManagerNm {
    text-transform: capitalize;
    margin-top: 5px;
}

.add_inner .extrafieldforContact:nth-child(even){background-color: var(--graylight)}

.add_inner .value .fieldheightdecr {
    width: 100%;
}


.add_inner .value textarea.internalnotefield {
    height: 58px;
    white-space: pre-line; 
    width: 100%;
}

.__customer_order_note_field {
    height: 63px;
    white-space: pre-line; 
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
}

.add_inner .value textarea.fieldheightdecr{
    height: 100px;
    white-space: pre-line;
}
.add_inner .value label{
    width: 100%;
    margin: 0;
}

.texttop{
    vertical-align: baseline;
}

.msl {
    font-size: 14px !important;
    font-weight: normal !important;
    background: var(--white) !important;
    height: 32px !important;
    border-radius: 4px !important;
    border: 1px solid var(--gray)
}
.msl-wrp {
    font-size: 14px !important;
    font-weight: normal !important;
    background: var(--white) !important;
    height: 32px !important;
    border-radius: 4px !important;
    width: 150px !important
}
.msl-input {
    outline: none !important;
    margin: 1px 1px !important;
    padding: 5px !important;
    font-size: 14px !important;
}
.msl-chip, .msl-single-value {
    margin: 2px !important;
    font-size: 14px !important;
    background: var(--white) !important;
}
.msl-grp-title, .msl-options .msl-option {
    font-size: 14px !important;
}
.msl-arrow-btn:hover, .msl-clear-btn:hover {
    background: none !important;
}

.msl-option-active {
    background: var(--gray) !important;
    color: var(--black) !important;
}

.msl:not(.msl-active,.msl-active-up):hover {
    border-color: var(--secondary) !important;
}

tr._order__danger__border {
    border: 2px solid var(--danger);
    background-color: pink;
}

.__task_manager_unreadBadge {
    background-color: var(--danger);
    text-align: center;
    border-radius: 15px;
    position: absolute;
    top: -13px;
    left: -13px;
    color: var(--white);
    min-height: 30px;
    min-width: 30px;
    line-height: 30px;
}

._add_button__modify__operation {
    width: auto;
    border: 1px solid var(--secondary);
    color: var(--black);
    padding: 5px 15px;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
}

._add_button__modify__operation:hover {
    background-color: var(--secondary);
    color: var(--white)
}

.inner__sub__fields__modify__operation {
    border: 1px solid var(--gray);
    padding: 20px;
    margin: 10px;
    border-radius: 2px;
}


.white__page__fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    border-top: 2px solid var(--graylight); 
    background-color: var(--white);
}

.pumpTableMaster_data {
    height: 50vh;
    overflow: scroll;
}

.employee___production__buttons {
    font-size: 35px;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    padding: 4px;
    margin: 4px;
}

.employee___production__buttons:hover {
    background-color: var(--primary);
    color: var(--white);
    border: 1px solid var(--primary);
}

/* React Select */

.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9, .RichTextEditor__editor___1QqIU pre {
    margin: 10px 0 !important;
    line-height: normal;
}

.preline {
    white-space: pre-line;
}
.extraFieldCloseButton {
    font-size: 20px;
    margin-left: 6px;
    color: var(--secondary);
    cursor: pointer;
}
.editscreenerror{
    padding: 0 20px;
}

.editCusomerBtn {
    cursor: pointer;
    color: var(--secondary);
    text-align: right;
}

.addSaveButton {
    padding: 10px 0;
    margin-left: 14%;
}

.addorder_tableHeader {
    padding: 10px 5px;
    background-color: var(--white);
}

.__terms_options_table tr:nth-child(odd) {
    background-color: var(--graylight);
}

textarea.__terms_option_area {
    min-height: 50px;
    margin-bottom: -5px;
}

.addorder_tableColumn {
    padding: 10px 5px;
}
.loader-image-div {
    margin: 50px 0;
}
.loader-image-cls {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 15px;
    bottom: 0;
}

.addorder_tableHeader .title {
    float: left;
    width: 14%;
}
.addorder_tableColumn .value {
    float: left;
    width: 14%;
}

.item_free_text_area {
    margin-bottom: -5px;
    height: 94px !important;
}

.custom-subs-table {
    margin: 0 14%;
}

.c-s-table-header {
    background-color: var(--secondary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.c-s-table-head {
    float: left;
    width: 25%;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
}

.c-s-table-body:nth-child(2n){
    background-color: var(--graylight);
}

.c-s-table-body-val1 {
    float: left;
    width: 25%;
    text-align: center;
    line-height: 35px;
    margin-top: 50px;
}
.c-s-table-body-val1 .big {
    font-size: 60px;
}

.c-s-table-body-val1 .small {
    font-size: 16px;
}
.c-s-table-body-val2 {
    float: left;
    width: 75%;
}
.c-s-table-data {    
    float: left;
    width: 33.33%;
    text-align: center;
    padding: 10px;
    font-weight: bold;
}
.c-s-table-data .renew-now {
    padding: 5px 0;
    font-size: 13px;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
    margin: 0px auto;
    cursor: pointer;
    border-radius: 3px;
    width: 110px;
}

.c-s-table-data .renew-now:hover {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: background-color 0.5s;
}


.addorder_tableColumn .value select{
    width: 70%;
    padding: 4px 0;
    margin-bottom: 5px;
}

.addorder_tableColumn .value input {
    width: 70%;
    padding: 4px 0;
    margin-bottom: 5px;
}
.addorder_tableColumn .value input[type=date]{
    width: 90%;
}
.___pdf_center_data {
    width: 712px;
    margin: 0 auto;
    background-color: var(--white);
}

/* TERMS & CONDITIONS CSS */

.__terms_left_header {
    float: left;
    width: 13%;
    margin-right: 4px;
}
.__terms_right_content {
    float: left;
    width: 80%;
}
.__terms_value_div {
    float: left;
    width: 45%;
    margin-bottom: 3px;
}
.__terms_values_new_css {
    border: 1px solid var(--grayoutline);
    padding: 5px;
}
.__terms_value_div.__terms_options {
    margin-right: 2%;
}
.__terms_content_title {
    padding: 5px 0;
    text-align: right;
    margin-right: 4px;
    padding-right: 4px;
}
.__terms_value_div select {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 30px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--white);
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}
.__terms_and_conditions {
    margin-top: 20px;
}
.__terms_remove_btn {
    float: left;
    margin-top: 2px;
}

.minh63 {
    min-height: 63px !important;
    margin-bottom: -5px;
}

.dataDetails {
    float: left;
    width: 75%;
}
.detailsHead {
    float: left;
    width: 20%;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 5px;
    padding-right: 4%;
}
.detailsValue {
    float: left;
    width: 70%;
    margin-bottom: 5px;
}
.dataDetailsHead {
    width: 40%;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 16px;
}

.setEditButton {
    background-color: var(--primary);
    padding: 5px 10px;
    min-width: 90px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    color: var(--white);
    margin-top: 30px;
    cursor: pointer;
    border: 1px solid var(--primary);
    border-radius: 3px;
}

.disabled-del-btn {
    color: var(--gray) !important;
    border: 1px solid var(--gray) !important;
    background-color: transparent !important;
    cursor: no-drop !important;
}

.setEditButton:hover {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: background-color 0.5s;
}

.demoTitle {
    color: var(--primary);
    font-size: 16px;
    margin: 20px 0 10px 0;
    font-weight: 600;
}
.demoHighlight {
    padding: 8px 5px;
    border: 1px solid var(--secondary);
    border-radius: 3px;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
}
.otherDemo {
    margin: 5px 0;
}

.contact_info_textarea {
    width: 75%;
    height: auto;
    border: 1px solid var(--grayoutline);
    min-height: 100px;
    margin-bottom: -7px;
}

  .editSetLeftContent{
    float: left;
    width: 20%;
    text-align: right;
    margin: 8px 0;
    margin-right: 1%;
  }

  .editSetRightContent{
    float: right;
    width: 79%;
  }

  .editSetRightContent select, .settEditSel {
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 14px;
    padding: 0 8px;
    -webkit-appearance: none;
    appearance: none;
    min-width: 50%;
    background-color: transparent;
    background-image: url('./theme/assets/Images/downarrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 32px auto;
}

.settEditSel {
    width: 100%;
}

.editSetRightContent .editSetText {
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    font-size: 14px;
    padding: 0 8px;
    -webkit-appearance: none;
    appearance: none;
    min-width: 50%;
    background-color: transparent;
}

.editSetRightContent .taxTextBox {
    min-width: 22%;
}
.editSettTable {
    margin: 0 13%;
}
.settingTable {
    width: 60%;
}

.editCrossButton {
    font-size: 16px;
    color: var(--secondary);
    cursor: pointer;
}

.pagination li {
    padding: 0 5px;
}

.pagination li {
    padding: 0 5px;
}
.pagination li a{
    color: var(--secondary);
    font-size: 16px;
}
.pagination li.active {
    background-color: var(--graylight);
    border-radius: 5px;
}
.mt-10px{
    margin-top: 10px;
    
}
.pl-20px{
    padding-left: 20px;
}

.print_popup_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255,255,255,0.8);
}
.print_popup_content {
    position: absolute;
    height: 210px;
    width: 315px;
    background-color: var(--white);
    padding: 20px;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 24%);
}
.input__type_number input::-webkit-outer-spin-button,
.input__type_number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input__type_number input[type=number] {
  -moz-appearance: textfield;
}

.pp_title {
    font-weight: 700;
    font-size: 16px;
    color: var(--secondary);
}
.pp_content {
    color: var(--gray);
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 20px 0;
}
.pp_textinput input{
    border-radius: 3px;
    min-height: 15px;
    border: 1px solid var(--grayoutline);
    padding: 5px;
    width: 100%;
}
.pp_btn {
    display: inline-block;
    margin: 0 0 0 24px;
    background-color: var(--white);
    border: 0;
    color: var(--secondary);
    padding: 12px 0 0 0;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    white-space: nowrap;
    cursor: pointer;
    outline: 0;
}

.pp_closeBtn {
    font-size: 16px;
    color: var(--secondary);
}

.drive-btn{
    float: left;
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    color: var(--white);
    cursor: pointer;
    background: var( --secondarylight);
    margin: 0 5px;
    line-height: 40px;
    font-size: 20px;
    border-radius: 5px;
}

.drive-btn:hover{
    color: var(--secondarylight);
    border: 1px solid var(--secondarylight);
    background-color: transparent;
    transition: background-color .5s;
}

.backup-database-title{
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 10px;
}

.file-upload-box {
    margin-bottom: 20px;
    display: inline-block;
    width: 40%;
}

.file-upload-box div {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.file-upload-box input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}

.file-upload-box .btn {
    padding: 20px 0;
    cursor: pointer;
    background-color: var(--gray);
    border: 3px dashed var(--graydark);
    color: var(--black);
    width: 100%;
    font-size: 20px;
}

.row-heading{
    font-size: 15px;
}

.email_verification__invalid_link {
    color: var(--danger);
    font-size: 24px;
    text-align: center;
    margin: 40px 0;
}

.email_verification__already_verified {
    color: var(--secondary);
    font-size: 24px;
    text-align: center;
    margin: 40px 0;
}

.email_verification__verified_success {
    color: var(--green);
    font-size: 24px;
    text-align: center;
    margin: 40px 0;
}

.text-area {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--grayoutline);
    height: 70px;
    border-radius: 0;
}

.__pause_note__title {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 18px;
}

span.dash_per_span {
    font-size: 12px;
    margin-left: 9px;
    color: #9c9c9c;
    margin-top: 18px;
}

.scrollToBottom {
    position: fixed;
    bottom: 40px;
    right: 5px;
    z-index: 2000;
}

.downarrow {
    transform: rotate(180deg);
}

.scrollToBottom img {
    width: 40px;
    cursor: pointer;
    animation: floating 3s linear 2s infinite;
    -webkit-animation: floating 3s linear 2s infinite;
}

@keyframes floating{
    0%{transform:translateY(0px)}
    50%{transform:translateY(20px)}
    100%{transform:translateY(0px)}
}
.boxesGroup {
    place-items: center;
    background-color: lightgrey;
    cursor: default;
}

.box {
    height: 100px;
    width: 150px;
    border-radius: 10px;
    cursor: move;
    float: left;
    margin: 20px;
}

tr.table-loading-effect td {
    height: 50px;
    vertical-align: middle;
    padding: 8px;
}
tr.table-loading-effect td span {
    display: block;
    height: 12px;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes moving-gradient {
    0% { background-position: -250px 0; }
    100% { background-position: 250px 0; }
}

.ticket-instructions-title {
    font-weight: bold;
    border-bottom: 1px solid var(--secondary);
    padding-bottom: 5px;
    color: var(--secondary);
}

.ticket-instructions {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.ticket-instructions li {
    padding: 0 0 15px 30px;
    background-image: url('./theme/assets/Images/left-arrow.png');
    background-size: 23px auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.ticket-instructions li a {
    color: var(--secondary)
}

.calendarEventPopUp-overlay {
    background-color: rgba(0,0,0,0.5);
}
.calendarEventPopUp-content {
    background-color: rgba(55,56,58,0.8);
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    max-width: 400px;
}
.calendarEvent-mainContent {
    padding: 10px;
    font-size: 15px;
}

.calendarEvent-mainContent .div-data {
    margin-bottom: 10px;   
}

.div-operation-buttons .ope-button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: var(--secondary);
    border-radius: 3px;
    cursor: pointer;
}

.div-date-buttons .ope-button {
    padding: 5px 20px;
    margin: 0 10px;
    background-color: var(--secondary);
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--secondary);
}
.calendar-date-change {
    border: 1px solid var(--secondary);
    padding: 5px 10px;
    border-radius: 5px;
}

.calendar-date-title {
    line-height: 35px;
    margin-right: 10px;
}