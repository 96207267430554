/****************************************
*                                       *
*       Custom Table CSS for OEMup      *
*       By Kirankumar Dafda             *
*       Date 1st July, 2021             *
*                                       *
****************************************/



.__kDoEM__table {
    /* overflow-x: auto; */
    width: 100%;
}

.__kDoEM__table svg {
    font-size: 16px;
    color: var(--secondary)
}

.__kDoEM__table thead th {
    font-size: 14px;
    font-weight: 100;
    color: var(--primary);
}

.__kDoEM__table thead th span{
    text-decoration: underline;
    cursor: pointer;
}

.__kDoEM__table thead th select, 
.__kDoEM__table thead th input {
    padding: 5px 5px 4px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid var(--gray);
    width: 100%;
}

.__kDoEM__table thead th select {
    background-image: url(./../../assets/Images/downarrow.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 25px auto;
}

.__kDoEM__table thead th input[type='date'] {
    width: 90%;
}

.__kDoEM__table tr:nth-child(even) {
    background-color: var(--graylight);
}

.__kDoEM__table thead th input[type='date']::-webkit-calendar-picker-indicator {
    color: var(--primary);
    opacity: 1
  }

.__kDoEM__table_pagination li a {
    color: var(--secondary);
    font-size: 19px;
}