/* Colors */

/* :root {
  --primary: #003557;
  --primarylight: #256a97;
  --secondary: #0079b4;
  --secondarydark: #5c77f1;
  --secondarylight: #2fb8de;

  --gray: #c1c1c1;
  --graydark: #dddddd;
  --graylight: #eceff2;
  --grayoutline: #bbbcbb;

  --white: #ffffff; 
  --black: #000000;
  --danger: #e21e15;
  --success: #256a97;

}  */

/*
  FIRST TRY

:root {
  --primary: #4C4D4F;
  --primarylight: #6a7ea5;

  --secondary: #9b6e41;
  --secondarydark: #9e8c79;
  --secondarylight: #E0D9D2;

  --gray: #c1c1c1;
  --graydark: #dddddd;
  --graylight: #eceff2;
  --grayoutline: #bbbcbb;

  --white: #ffffff; 
  --black: #000000;
  --danger: #e21e15;
  --success: #256a97;
} 
*/

:root {
  --primary: #37383a;
  --primarylight: #434f68;

  --secondary: #9b6e41;
  --secondarydark: #9e8c79;
  --secondarylight: #E0D9D2;

  --gray: #c1c1c1;
  --graydark: #dddddd;
  --graylight: #eceff2;
  --grayoutline: #bbbcbb;

  --white: #ffffff;
  --black: #000000;
  --danger: #e21e15;
  --success: #9b6e41;
}

.clr-primary {
  color: var(--primary)
}

.clr-primarylight {
  color: var(--primarylight)
}

.clr-secondary {
  color: var(--secondary)
}

.clr-secondarydark {
  color: var(--secondarydark)
}

.clr-secondarylight {
  color: var(--secondarylight)
}

.clr-gray {
  color: var(--gray)
}

.clr-graydark {
  color: #808080;
}

.clr-graylight {
  color: var(--graylight)
}

.clr-white {
  color: var(--white)
}

.clr-black {
  color: var(--black)
}

.clr-danger {
  color: var(--danger)
}

.clr-success {
  color: var(--success)
}



@font-face {
  font-family: 'martel_sansblack';
  src: url('./theme/assets/Fonts/martelsans-black-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/martelsans-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'denmarkregular';
  src: url('./theme/assets/Fonts/films.denmark-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/films.denmark-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'martel_sansextrabold';
  src: url('./theme/assets/Fonts/martelsans-extrabold-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/martelsans-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'martel_sanslight';
  src: url('./theme/assets/Fonts/martelsans-light-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/martelsans-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'martel_sansregular';
  src: url('./theme/assets/Fonts/martelsans-regular-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/martelsans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'martel_sanssemibold';
  src: url('./theme/assets/Fonts/martelsans-semibold-webfont.woff2') format('woff2'),
    url('./theme/assets/Fonts/martelsans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: var(--graylight);
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  min-width: 900px;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}


body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--graylight);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: var(--primary);
}

::selection {
  color: var(--white);
  background: var(--primary);
}

a {
  color: var(--primary)
}

a:hover {
  color: var(--secondary);
}

span:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--success) !important;
}

input:hover,
select:hover,
textarea:hover,
.inp-style:hover {
  border: 1px solid var(--secondary) !important
}

.Dropdown__root___3ALmx select {
  border: 4px solid transparent !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  padding: 0;
  margin: 0;
}

.clear {
  clear: both;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.nb-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 75px;
  height: 75px;
  margin: auto;
  background: transparent;
  border-top: 4px solid #009688;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.pageLoading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: progress;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2001;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

table,
tbody {
  position: relative;
}

svg {
  cursor: pointer;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.splash__center {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.splash__logo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 200px;
  width: auto;
}

.pointer {
  cursor: pointer;
}

.form-bg-primary {
  background-color: var(--primary) !important;
}

.authBg {
  background-color: var(--primary) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.loging-menu-card {
  background-color: var(--white);
  border-radius: 5px;
  padding: 40px 60px;
  width: 550px;
  transition: height .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.logo img {
  width: 150px;
}

.form .form-group {
  margin-top: 25px;
  margin-bottom: 0px;
}

.form label {
  font-size: 16px;
  margin-bottom: 10px;
}

.form input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 1.0625rem 1.25rem;
  font-size: 15px;
  line-height: 1.3;
  color: var(--black);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--graylight);
  border-radius: 0;
  transition: border-color .25s ease-in-out, box-shadow .25s ease-in-out;
  height: 50px;
}

.form input:hover {
  border: 1px solid var(--secondary);
}

.form input:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--success);
}

.sign-btn {
  margin-top: 30px;
  padding: 10px 32px !important;
  display: block;
  position: relative;
  z-index: 3;
  text-align: center;
  background-color: var(--primary);
  background-image: linear-gradient(254deg, var(--secondarydark), var(--secondarylight));
  color: var(--white);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.5s;
}

.onlysignup__btn__css {
  margin-top: 58px;
}

.sign-btn:hover {
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px var(--secondarydark);
  color: var(--black);
  text-decoration: none;
}

.sign-btn-outline,
.forgot-btn-outline,
.back-btn-404-outline {
  width: 100%;
  text-align: center;
  box-shadow: inset 0 0 0 1px var(--secondarydark);
  padding: 14px 0;
  color: var(--black);
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 30px;
  transition: 0.5s;
}

.sign-btn-outline:hover,
.forgot-btn-outline:hover {
  /* background-color: var(--secondarydark); */
  background-image: linear-gradient(254deg, var(--secondarydark), var(--secondarylight));
  color: var(--white);
  box-shadow: none;
  text-decoration: none;
}

.secure-btn {
  margin-top: 30px;
  font-weight: bold;
  padding: 14px 0;
}

.page__not__found {
  text-align: center;
  color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 170px;
}

.back-btn-404 {
  margin: auto;
  margin-top: 25px;
  padding: 10px 32px !important;
  display: block;
  position: relative;
  z-index: 3;
  text-align: center;
  background-color: var(--primary);
  background-image: linear-gradient(254deg, var(--secondarydark), var(--secondarylight));
  color: var(--white);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.5s;
}

.back-btn-404:hover {
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px var(--secondarydark);
  color: var(--white);
  text-decoration: none;
}

.signupacceptterms {
  margin-top: 15px;
  font-size: 14px;
  text-align: right;
}

.gobackbtn {
  margin-top: 15px;
  font-size: 14px;
}

.form-heading .form-title {
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-heading p {
  font-size: 14px;
}

@media (max-width: 468px) {
  .form-bg-primary {
    background-color: transparent !important;
  }

  .loging-menu-card {
    padding: 30px 15px;
    width: 100%;
    position: static;
    transform: translate(0%, 0%);
  }

  .signupacceptterms {
    text-align: left;
  }
}

.style_toast-list__378gH {
  top: 20px !important;
}

.errornotification,
.successnotification {
  margin-top: 20px;
  border: 1px solid var(--danger);
  border-radius: 5px;
  margin-bottom: 0;
  background-color: var(--white);
}

.successnotification {
  border: 1px solid var(--success);
}

.successnotification .notificon,
.errornotification .notificon {
  background-color: var(--danger);
  padding: 10px;
  vertical-align: middle;
  margin-right: 10px;
  color: var(--white);
  font-size: 3em;
}

.successnotification .notificon {
  background-color: var(--success);
}

.errornotification .notiflink {
  color: var(--danger);
}

.registernotiflink {
  padding: 10px;
}

.successnotification .notiflink {
  color: var(--success);
}

.forgotpass__success_notification {
  padding: 5px 15px;
  color: var(--success);
}

.forgotpass__fail_notification {
  padding: 5px 15px;
  color: var(--danger);
}

/* Home Page Style Start */

.tickets {
  float: left;
  width: 200px;
  height: 55px;
  padding-top: 240px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: var(--graylight);
  margin: 10px;
  background-color: var(--white);
  color: var(--black);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  text-decoration: none;
}

.tickets i {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 190px;
  /* background-image: linear-gradient(226deg,var(--primarylight),var(--primary)); */
  background-image: linear-gradient(226deg, var(--secondarydark), var(--secondarylight));
  text-align: center;
  font-size: 48px;
}

.tickets:hover {
  color: var(--black);
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.5s;
}

.tickets span {
  display: block;
  position: absolute;
  bottom: 14px;
}

/* .tickets.settings i,
.tickets.demodata i,
.tickets.freeuse i,
.tickets.helpcenter i {
  background-image: linear-gradient(226deg,var(--secondarydark),var(--secondarylight));
} */

.homenotification {
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid var(--secondarydark);
  margin-right: 20px;
  border-radius: 5px;
  margin-bottom: 0;
  background-color: var(--white);
}

.homenotification .notificon {
  background-color: var(--secondarydark);
  padding: 10px;
  vertical-align: middle;
  margin-right: 10px;
  color: var(--white);
  font-size: 3em;
}

.homenotification .notiflink {
  color: var(--black);
}

.tickets i::before,
.tickets i::after {
  display: block;
  position: relative;
  z-index: 2;
  width: 1em;
  height: 1em;
  left: 50%;
  top: 50%;
  margin: -0.5em 0 0 -0.5em;
  content: ' ';
  border-radius: 50%;
  background-color: var(--white);
  background-size: cover;
}

.tickets i::before {
  background-image: url(./theme/assets/Images/sprite.svg);
  background-repeat: no-repeat;
}

.tickets.dashboard i::before {
  background-position: calc(0em - 1px) 0px;
}

.tickets.crm i::before {
  background-position: calc(-1em - 3px) -1px;
}

.tickets.mpp i::before {
  background-position: calc(-2em - 1px) 0px;
}

.tickets.proplan i::before {
  background-position: calc(-3em - 1px) 0px;
}

.tickets.stock i::before {
  background-position: calc(-4em - 1px) 0px;
}

.tickets.procurement i::before {
  background-position: calc(-5em - 1px) 0px;
}

.tickets.accounting i::before {
  background-position: calc(-17em - 1px) 0px;
}

.tickets.settings i::before {
  background-position: calc(-6em - 1px) 0px;
}

.tickets.demodata i::before {
  background-position: calc(-7em - 1px) 0px;
}

.tickets.freeuse i::before {
  background-position: calc(-8em - 1px) 0px;
}

.tickets.helpcenter i::before {
  background-position: calc(-10em - 1px) 0px;
}

.tickets i::after {
  width: 2em;
  height: 2em;
  margin: -1.5em 0 0 -1em;
  z-index: 1;
  background-color: var(--white);
  opacity: .3;
}


.ticketImage {
  height: 150px;
  width: 165px;
  position: relative;
}



.ticketText {
  background-color: var(--white);
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
}

/* Home Page Style Ends */



/* Dashboard Page Style Starts */
.dashboardTickets {
  height: 200px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
  font-size: 14px;
  font-weight: 600;
  color: var(--graylight);
  background-color: var(--white);
  border-radius: 3px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  float: left;
  text-decoration: none;
  cursor: move;
  box-sizing: border-box;
  transition: box-shadow .3s;
  margin: 10px;
}

.dashboardTickets:hover {
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 15%);
}

.dashTickMargin {
  margin: 15px 0;
}



.DticketImage {
  white-space: nowrap;
  font-weight: 600;
  flex-grow: 1;
  /* padding-top: 10px; */
  font-size: 45px;
  width: 100%;
  /* padding: 0 10px; */
  /* box-sizing: border-box; */
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  cursor: move;
}

.DticketImage.nodata {
  white-space: normal;
  font-size: 1em;
  line-height: normal;
}

.dticketText {
  font-size: 1rem;
  line-height: 1.2rem;
  height: 90px;
  flex-grow: 0;
  cursor: move;
  font-weight: 600;

  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

.dticketText::after {
  height: 4px;
  width: 100%;
  content: ' ';
  order: 10;
  position: absolute;
  bottom: 0;
  left: 0;
}

.dticketText.dark::after {
  background-image: linear-gradient(269deg, var(--primarylight), var(--primary));
}

.dticketText.light::after {
  background-image: linear-gradient(269deg, var(--secondarydark), var(--secondarylight));
}

/* Dashboard Page Style Ends */

.home-main-container {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
}

.main-container {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
  background-color: var(--white);
}

/* TOP HEADER CSS Starts */

.header {
  padding: 0 20px;
  border-bottom: 1px solid var(--graylight);
  background-color: var(--graylight);
}

.top-header {
  padding: 11px 20px;
}

.header-Logo img {
  height: 40px;
  background-color: var(--white);
  border-radius: 4px;
}

.home-tab {
  display: inline-block;
  font-size: 15px;
  color: var(--gray);
  padding: 0px 10px;
  line-height: 40px;
}

.header-right-tab {
  display: inline-block;
  font-size: 15px;
  padding: 0px 10px;
  line-height: 48px;

}

.user-loging {
  color: var(--black)
}

.user-logout {
  line-height: unset;
  color: var(--secondary);
  font-size: 27px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.user-logout:hover {
  background-color: var(--secondary);
  color: var(--white) !important;
}


/* TOP HEADER CSS ENDS */


/* MENU CSS START */

.menus .menu {
  font-size: 48px;
  display: block;
  width: 1em;
  height: 1em;
  border-right: solid 1px var(--graylight);
  border-left: 0;
  border-top: 0;
  float: left;
  cursor: pointer;
}

.menus .menu::before {
  display: block;
  content: ' ';
  font-size: 40px;
  width: 1em;
  height: 1em;
  margin: calc(1em * .1);
  background-size: 720px 40px;
  background-image: url(./theme/assets/Images/sprite.svg);
  background-repeat: no-repeat;
}

.menus .menu.active {
  background-color: var(--graylight);
}



.menu.back::before {
  background-position: calc(-12em - 1px) 0px;
}

.menu.home::before {
  background-position: calc(-13em - 1px) 0px;
}

.menu.dashboard::before {
  background-position: calc(0em - 1px) 0px;
}

.menu.co::before {
  background-position: calc(-1em - 1px) 0px;
}

.menu.mpp::before {
  background-position: calc(-2em - 1px) 0px;
}

.menu.mo::before {
  background-position: calc(-3em - 1px) 0px;
}

.menu.stock::before {
  background-position: calc(-4em - 1px) 0px;
}

.menu.procure::before {
  background-position: calc(-5em - 1px) 0px;
}

.menu.account::before {
  background-position: calc(-17em - 1px) 0px;
}

.menu.setting::before {
  background-position: calc(-6em - 1px) 0px;
}

.menu.demodata::before {
  background-position: calc(-7em - 1px) 0px;
}

.menu.freeuse::before {
  background-position: calc(-8em - 1px) 0px;
}

.menu.helpcenter::before {
  background-position: calc(-10em - 1px) 0px;
}


/* MENU CSS END */

.inline {
  display: inline;
}

/* HEADER SUB MENU STARTED */

.innerHeader {
  margin: 10px 0;
}

.innerHeaderList {
  text-decoration: none;
  font-size: 15px;
  color: var(--primary);
  margin-right: 20px;
}

.innerHeaderList.active {
  color: var(--secondary);

}


/* HEADER SUB MENU ENDED */

.demoTitle {
  color: var(--primary);
  font-size: 16px;
  margin: 20px 0 10px;
  font-weight: 600;
}

.demoHighlight {
  padding: 8px 5px;
  border: 1px solid var(--secondary);
  border-radius: 3px;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
}

.otherDemo {
  margin: 5px 0;
}

._customer_table_selection {
  height: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 1px solid var(--gray);
  font-size: 16px;
  margin: 15px 0;
  padding: 0 8px;
  width: 140px;
  background-color: transparent;
  background-position: 100%;
  background-image: url(./theme/assets/Images/downarrow.svg);
  background-repeat: no-repeat;
  background-size: 25px auto;
}

.width5 {
  width: 5% !important;
}

.width10 {
  width: 10% !important;
}

.width13 {
  width: 13% !important;
}

.width15 {
  width: 15% !important;
}

.width20 {
  width: 20% !important;
}

.width25 {
  width: 25% !important;
}

.width30 {
  width: 30% !important;
}

.width35 {
  width: 35% !important;
}

.width40 {
  width: 40% !important;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60% !important;
}

.width70 {
  width: 70% !important;
}

.width80 {
  width: 80% !important;
}

.width85 {
  width: 85% !important;
}

.width90 {
  width: 90% !important;
}

.width99 {
  width: 99% !important;
}

.width100 {
  width: 100% !important;
}

.primary-color {
  color: var(--primary) !important;
}

.secondary-color {
  color: var(--secondary) !important;
}


.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.scrollDown {
  display: none;
  font-size: 40px;
}

.scrollDownShow {
  display: block;
  font-size: 40px;
}

.scrollView {
  z-index: 70000;
  position: fixed;
  float: right;
  right: 0;
  bottom: 0;
  cursor: pointer;
  padding: 10px 10px;
}

.pratytitle {
  float: left;
  width: 48%;
  padding: 5px 0;
  text-align: right;
  margin-right: 10px;
}

.partyvlaue {
  text-align: center;
  float: right;
  width: 48%;
  position: relative;
}

.register_bg {
  background: var(--graylight);
}

.register_div {
  float: right;
  width: 70%;
  margin-left: 10px;
  position: relative;
  margin-bottom: 10px;
}